<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language mr-md-1 mt-0 mb-0 d-flex align-self-center"
    :class="{'landing-page': isLandingPage}"
    right
  >
    <template #button-content>
      <img
        v-if="!noIcon"
        :src="`${$apiUrl}/images/icons/language.svg`"
        class="dd-mr-50"
        :class="{
          'profile-img-height profile-img-height-2 language_text_icon': !isLandingPage, 
          'd-none d-md-inline-block profile-img-height-3': isLandingPage
        }"
        :alt="`Language Selection: ${currentLocale.locale}`"
      />
      <span 
        class="text-uppercase language_text" 
        :class="{
          'd-none': !isLandingPage,
          'd-md-none d-inline-block': isLandingPage
        }"
      >
        {{ $t('Language') }}
      </span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLocale(localeObj.locale)"
      class="text-uppercase"
    >
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import eventBus from '@/libs/event-bus'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  props: {
    noIcon: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      locales: [
        {
          locale: 'en',
          name: 'English',
        },
        {
          locale: 'hi',
          name: 'Hindi',
        },
        {
          locale: 'ar',
          name: 'Arabic',
        },
        {
          locale: 'de',
          name: 'German',
        },
        {
          locale: 'es',
          name: 'Spanish',
        },
        {
          locale: 'fr',
          name: 'French',
        },
        {
          locale: 'ru',
          name: 'Russian',
        },
        {
          locale: 'uz',
          name: 'Uzbek',
        },
        {
          locale: 'id',
          name: 'Indonesian',
        },
        {
          locale: 'ur',
          name: 'Urdu',
        },
        {
          locale: 'pt',
          name: 'Portuguese',
        },
        {
          locale: 'bn',
          name: 'Bengali',
        },
        {
          locale: 'zh',
          name: 'Chinese',
        },
        {
          locale: 'ta',
          name: 'Tamil',
        },
        {
          locale: 'it',
          name: 'Italian',
        },
        {
          locale: 'th',
          name: 'Thai',
        },
        {
          locale: 'ml',
          name: 'Malayalam',
        },
        {
          locale: 'tr',
          name: 'Turkish',
        },
      ],
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    isLandingPage: {
      get(){
        const allowedRoutes = [
          'landing-page',
          'user-landingpage',
          'landing-shop-page',
          'landing-provider-page',
          'blog',
          'knowledge-base',
          'faq',
          'contact-us',
          'about-us',
          'privacy-policy',
          'terms-and-condition',
        ]
        return allowedRoutes.some(r => r == this.$route.name)
      }
    },
    isMobileView: {
      get(){
        if(this.isLandingPage) return false;
        return this.$store.getters['app/windowSize'] <= 767
      }
    },
  },
  mounted() {
    eventBus.$on('set-language-locale', locale => {
      this.setLocaleLang(this.locales.find(x => x.locale === locale) ? locale : 'en')
    })
  },
  destroyed() {
    eventBus.$off('set-language-locale')
  },
  methods: {
    setLocaleLang(locale) {
      window.SuperApp.helper.changeTranslation(this, locale)
    },
    setLocale(locale) {
      window.SuperApp.getters.setLang(locale)
      this.setLocaleLang(locale)
    },
  },
}
</script>

<style>
@media only screen and (max-width: 767px) {
  .dropdown-language ul.dropdown-menu.dropdown-menu-right a {
    padding: 0.65rem 1.28rem !important;
  }
  
  #language-selection-dropdown ul.dropdown-menu {
    top: 0 !important;
    bottom: 0;
  }
  #language-selection-dropdown li > a.nav-link {
    margin-left: 0;
    padding-left: 0;
  }
}
.dropdown-language .dropdown-menu {
  max-height: 550px !important;
  overflow-y: scroll !important;
}
</style>
<style scoped>
.profile-img-height-2{
	width: 32px !important;
	height: 32px !important;
}
.profile-img-height-3{
	width: 32px !important;
	height: 32px !important;
}
.dropdown-menu-right .language_text{
	display: inline-block !important;
  text-transform: capitalize !important;
}
@media only screen and (max-width: 767px) {
  .profile-img-height-2{
    width: 16px !important;
    height: 16px !important;
  }
  .auth-v2 .profile-img-height-2{
    width: 32px !important;
    height: 32px !important;
  }
}
</style>
