/* eslint-disable eol-last */
/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
const facebookAppId = '518236876494478'

// eslint-disable-next-line import/prefer-default-export
export function initFacebookSdk() {
    window.fbAsyncInit = function() {
        window.FB.init({
            appId: facebookAppId,
            cookie: true,
            xfbml: true,
            version: 'v3.2',
        })

        window.FB.AppEvents.logPageView()
    };

    // load facebook sdk script
    // eslint-disable-next-line space-before-function-paren
    (function(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) { return }
        const js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
}

export function getFbUserData() {
    window.FB.api('/me', { locale: 'en_US', fields: 'id,first_name,last_name,email,link,gender,locale,picture' },
        function(response) {
            if (window.socialLoginParams) {
                window.socialLoginParams({
                  social_unique_id: response.id,
                  login_by: 'FACEBOOK',
                  first_name: response.first_name,
                  last_name: response.last_name,
                  email: response.email,
                  picture: response.picture && response.picture.data ? response.picture.data.url : '',
                })
            }
        })
}

export function fbLogin() {
    window.FB.login(function(response) {
        if (response.authResponse) {
            // Get and display the user profile data
            getFbUserData()
        } else {
            console.log('User cancelled login or did not fully authorize.')
        }
    }, { scope: 'email' })
}