import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//     apiKey: "AIzaSyB3RSlKZz_om-mMxVCx6PqGyYAhb3PtZIE",
//     authDomain: "mom-pwa.firebaseapp.com",
//     projectId: "mom-pwa",
//     storageBucket: "mom-pwa.appspot.com",
//     messagingSenderId: "377636716120",
//     appId: "1:377636716120:web:bfb14a01657e51ae0b942d",
//     measurementId: "G-E6QRW4EQ2N"
// };

// const firebaseConfig = {
//     apiKey: "AIzaSyCP5rg8PsrjIVAz02DMlmqghZuXsmW5NGs",
//     authDomain: "brijesh-ladani-57.firebaseapp.com",
//     databaseURL: "https://brijesh-ladani-57-default-rtdb.firebaseio.com",
//     projectId: "brijesh-ladani-57",
//     storageBucket: "brijesh-ladani-57.appspot.com",
//     messagingSenderId: "1063532310189",
//     appId: "1:1063532310189:web:6001de91d02a930fd42024",
//     measurementId: "G-ZTLH62WNSS"
// };

// const firebaseConfig = {
//     apiKey: "AIzaSyCnqxbnuuTgPjNpORztrf-J5iow43Mf6tY",
//     authDomain: "mom-push.firebaseapp.com",
//     projectId: "mom-push",
//     storageBucket: "mom-push.appspot.com",
//     messagingSenderId: "628083987797",
//     appId: "1:628083987797:web:56aebb4874d55e0f1f0bdc",
//     measurementId: "G-9BWDZR4H9D"
// };
 
const firebaseConfig = {
    apiKey: "AIzaSyCdacZyFc9nU9jm6FUFdtJynEi4VZPX2Hc",
    authDomain: "momitnow.firebaseapp.com",
    projectId: "momitnow",
    storageBucket: "momitnow.appspot.com",
    messagingSenderId: "202105772131",
    appId: "1:202105772131:web:3425e46603b34eefd44056",
    measurementId: "G-EV8YJX9DY0"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // new Notification(payload.notification.title, {
    //     icon: payload.notification.icon,
    //     body: payload.notification.body,
    // })
});

export default messaging;
