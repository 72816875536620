/* eslint-disable eol-last */
import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  // eslint-disable-next-line object-curly-spacing
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    // eslint-disable-next-line object-curly-spacing
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    axios.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        const { response } = error

        if (response && response.status === 401 && jwtDefaultConfig.reloadOn401) {
          localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('TokenKeyName'))
          localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('RefreshTokenKeyName'))
          window.SuperApp.getters.removeUserInfo()
          window.SuperApp.getters.removeUserAccess()
          window.location.reload()
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  // eslint-disable-next-line class-methods-use-this
  getToken() {
    return localStorage.getItem(window.SuperApp.getters.getPrefixStorage('TokenKeyName'))
  }

  // eslint-disable-next-line class-methods-use-this
  getRefreshToken() {
    return localStorage.getItem(window.SuperApp.getters.getPrefixStorage('RefreshTokenKeyName'))
  }

  // eslint-disable-next-line class-methods-use-this
  setToken(value) {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('TokenKeyName'), value)
  }

  // eslint-disable-next-line class-methods-use-this
  setRefreshToken(value) {
    localStorage.setItem(window.SuperApp.getters.getPrefixStorage('RefreshTokenKeyName'), value)
  }

  login(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.userLogin}`, ...args)
  }

  loginSocial(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.userSocialLogin}`, ...args)
  }

  providerLogin(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.providerLogin}`, ...args)
  }

  providerSocailLogin(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.providerSocailLogin}`, ...args)
  }

  agentLogin(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.agentLogin}`, ...args)
  }

  shopLogin(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.shopLogin}`, ...args)
  }

  shopSocailLogin(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.shopSocailLogin}`, ...args)
  }

  adminLogin(...args) {
    return this.axiosIns.post(`${this.jwtConfig.loginEndpoint}${this.jwtConfig.adminLogin}`, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
